/* .second-div {
    margin-left: 16%;
} */

.second-right-side-text-dropdown {
  position: absolute;
  width: 12px;
  height: 8px;
  left: 1256px;
  top: 266px;

  background: #525252;
  transform: rotate(-180deg);
}

.mandated-article-image {
  width: 100%;
  height: 400px;
  left: 184px;
  top: 312px;
  border-radius: 6px;
}

.mandated-article-footer {
  width: 100%;
  background: #ebebeb;
  border-radius: 6px !important;
}

.mandated-article-footer-box1 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;

  height: 28px;
  border: 1.2px solid #3d3d3d;
  border-radius: 100px;
}

.-title {
  margin-left: 5%;

  font-family: "Metropolis";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;

  color: #3d3d3d;
}

.mandated-article-footer-title1 {
  height: 35px;
  left: 216px;
  top: 784px;

  margin-left: 5%;
  font-family: "Canela";
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 110%;

  color: #221d35;
}

.mandated-article-footer-title2 {
  margin-left: 5%;
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 110%;

  color: #3d3d3d;
}

.mandated-article-footer-title3 {
  margin-left: 5%;
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;

  text-transform: uppercase;
}

.mandated-article-arrow {
  width: 24px;
  color: #a891f5;
}

.mandated-article-line {
  width: 125px;
  height: 0px;
  left: 217px;
  top: 907px;
  border-top: 2px solid;
  margin-left: 5%;
  margin-top: 5px;
}

/* Mobile view */
@media only screen and (max-width: 992px) {
  .mandated-title {
    padding-top: 2em;
  }
  .mandated-link {
    text-decoration: none;
  }
}
