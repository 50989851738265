@import url(../../App.css);

.pcfp-container {
  display: flex;
}

.pcfp-l-cotnainer {
  width: 50%;
}

.pcfp-r-cotnainer {
  width: 50%;
  padding: 2em;
  position: relative;
}

.pcfp-l-image {
  width: 100%;
  height: 91vh;
  object-fit: cover;
  object-position: center;
}

.pcfl-back-icon {
  color: var(--themeDarkBlueTextColor);
  font-size: 16px;
  text-decoration: none;
}

.pcfp-r-smll-head {
  color: var(--theme-voilet);
  margin-top: 6em;
}

.pcfp-c-select-container {
  margin-top: 4em;
  border: 1px solid #8f8f8f;
  padding: 10px 20px;
  border-radius: 8px;
}

.pcfp-c-select {
  outline: none;
  border: unset;
  font-family: var(--MetropolisMedium);
  color: var(--themeDarkBlueTextColor);
  width: 100%;
  font-size: 16px;
  cursor: pointer;
}

.pcfp-bottom-container {
  position: absolute;
  bottom: 0;
  left: 0;
}

/* .pcfp-bottom-b-container {
  text-align: right;
  margin-right: 2em;
} */

/* .pcfp-bottom-loader-container {
  margin-top: 3em;
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 5px;
  background-color: var(--themeDarkBlueTextColor);
} */

.pcfp-bottom-loader-container div {
  display: table-cell;
  height: 100%;
}

.pcfp-bottom-loader-complete {
  display: table-cell;
  background-color: var(--theme-voilet);
}

.pcfp-step {
  display: none;
}

/* Mobile view */
@media only screen and (max-width: 992px) {
  .pcfp-container {
    display: block;
  }
  .bottom-box {
    padding-right: 3% !important;
  }
  .large-heading {
    text-align: center;
  }

  /* .pcfp-l-cotnainer {
    display: none;
  } */

  /* .pcfp-r-cotnainer {
    width: unset !important;
    padding: 2em 2em 2em !important;
  } */
  .pcfp-small-text {
    color: #a891f5 !important;
  }
  .pcfp-head {
    font-size: 30px !important;
  }

  /* .pcfp-bottom-b-container {
    display: none;
  } */

  .pcfp-bottom-container {
    /* position: unset !important; */
    /* padding-top: 22em !important; */
  }
  .pcfp-bottom-loader-container {
    margin-top: 10px !important;
  }
  .pcfp-step {
    display: unset;
  }
}
