@import url(../../App.css);

.article-card-container {
  max-width: 580px;
}

.article-card-heading {
  font-family: var(--MetropolisMedium);
  font-weight: 500;
  font-size: 24px;
  margin-top: 20px;
}

.article-card-content {
  line-height: 150%;
  min-height: 100px;
}

.artical-card-img-div {
  width: 100%;
  height: 380px;
  overflow: hidden;
  border-radius: 5%;
}

.article-card-image {
  width: 100%;
  min-height: 100%;
}
