  .rewsroom-parent-div {
  margin: 5% 10% 15% 10% !important;
  /* background-color: red; */
}

.newsroom-title {
  width: 206px;
  height: 58px;

font-family: var(--CanelaLight);
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 120%;

  letter-spacing: -0.02em;

  color: #403663;
}

.newsroom-first-section {
  margin-bottom: 5%;
}

.image {
  width: 100%;
  border-radius: 6px;
}

.newsroom-second-block {
  padding: 4% 0% 0% 3% !important;
}

.newsroom-second-text1 {
  width: 71px;
  height: 16px;

  font-family: var(--MetropolisMedium);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;

  color: #a891f5;
}

.newsroom-second-text2 {
  width: 346px;
  height: 126px;
  font-family: var(--CanelaLight);
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 130%;

  letter-spacing: -0.02em;

  color: #403663;
}

.newsroom-second-text3 {
  width: 346px;
  height: 78px;

    font-family: var(--MetropolisMedium);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
}

.newsroom-second-text4 {
  width: 97px;
  height: 26px;

  font-family: var(--MetropolisMedium);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;

  text-decoration-line: underline;
  text-transform: uppercase;

  color: #a891f5;
}

/* section 2 */
.newsroom-second-section-content {
  margin-top: 4%;
}

.newsroom-second-section-text1 {
  width: 154px;
  height: 16px;

  font-family: var(--MetropolisMedium);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #a891f5;
}

.newsroom-second-section-text2 {
  /* height: 68px; */
  font-family: var(--MetropolisMedium);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;

  color: #403663;
  /* min-height: 230px; */
}

.newsroom-second-section-text3 {
  width: 97px;
  height: 26px;

    font-family: var(--MetropolisMedium);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;

  text-decoration-line: underline;
  text-transform: uppercase;

  color: #403663;
}

.maindivnews {
  width: 100%;
  margin: auto;
  /* display: flex; */
  justify-content: space-between;
  /* position: relative; */
  background-image: url("https://d2s80gmgq3vhu4.cloudfront.net/HomeSlideImages/assets//Images/noiseBG.png");
  background-repeat: repeat;

  padding: 1.4em 4.5em;
  background-color: var(--white);
  z-index: 1000;
  border-top: 5px solid #f5f5f5;
}
/* Mobile View */
 .small-text5 {
  font-family: var(--MetropolisMedium);
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 115%;
   margin: unset;
   display: flex;
   text-align: left;
   /* justify-content: center; */
   margin-bottom: 1em;
   color: #403663;
 }
    .small-text99 {
      font-family: var(--MetropolisMedium);
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 115%;
      margin: unset;
      display: flex;
      text-align: center;
      justify-content: center;
      margin-bottom: 1em;
      color: #403663;
    }
    .small-text55 {
        font-family: var(--MetropolisMedium);
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 115%;
      margin: unset;
      display: flex;
      text-align: left;
      justify-content: center;
      margin-bottom: 1em;
      color: #403663;
    }

@media only screen and (max-width: 992px) {
  .mv-news {
    flex-direction: column;
  }
  .newsroom-second-text2 {
    width: unset;
    height: unset;
    font-size: 20px;
  }
  .newsroom-second-section-text2 {
    width: unset;
    height: unset;
    font-size: 16px;
  }
  .small-text5{
        font-family: var(--MetropolisMedium);
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 115%;
          margin: unset;
          display: flex;
          text-align: left;
          /* justify-content: center; */
          margin-bottom: 1em;
          color: #403663;
  }
  .newsroom-second-text3 {
    width: unset;
    height: unset;
    font-size: 14px;
  }
  .newsroom-second-text4 {
    font-size: 14px;
  }
  .newsroom-second-section-text3 {
    font-size: 14px;
  }
  .rewsroom-parent-div {
    margin: 5% 7% 15% 7% !important;
  }
  .mv-news-items {
    width: unset !important;
  }
  .mv-news-items:first-child {
    padding-top: 3em;
  }
  .mv-news-items:last-child {
    padding-top: 3em;
  }
}

.news-img-div {
  /* max-height: 300px !important; */
  width: 100%;
}

@media only screen and (min-width: 1200px){
  .mv-news-items{
    padding-left: 15px;
  }
}