header {
  width: 100%;
}

.containernav {
  /* width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-image: url("../../assets//Images/noiseBG.png");
  background-repeat: repeat;

  padding: 1em 4.5em;
  background-color: var(--white);
  z-index: 1000; */
  background-color: var(--white);
  background-image: url("https://d2s80gmgq3vhu4.cloudfront.net/HomeSlideImages/assets//Images/noiseBG.png");
  background-repeat: repeat;
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding: 0 4.5em;
  position: relative;
  width: 100%;
  z-index: 1000;
  position: fixed;
  align-items: center;
}

nav > ul {
  display: flex;
  column-gap: 2em;
}

.ull {
  padding-left: 0px;
  /* padding-top: 11px !important; */
}
nav > ul > li {
  list-style: none;
}
nav > ul > li a {
  text-decoration: none;
}

.megamenuservice {
  position: absolute;
  top: 95%;
  width: 100%;
  height: 400px;
  opacity: 0;
  visibility: hidden;
  left: 0px;
  /* box-shadow: 0px 10px 2px 3px rgba(0, 0, 0, 0.076); */
  transition: 0.5s;
}

nav > ul > li:hover .megamenuservice {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.megamenuinsights {
  position: absolute;
  top: 95%;
  width: 100%;
  height: 400px;
  opacity: 0;
  visibility: hidden;
  left: 0px;
  /* box-shadow: 0px 10px 2px 3px rgba(0, 0, 0, 0.076); */
  transition: 0.5s;
}
.megamenuinsights1 {
  position: absolute;
  top: 95%;
  width: 100%;
  height: 300px;
  opacity: 0;
  visibility: hidden;
  left: 0px;
  /* box-shadow: 0px 10px 2px 3px rgba(0, 0, 0, 0.076); */
  transition: 0.5s;
}

nav > ul > li:hover .megamenuinsights {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
nav>ul>li:hover .megamenuinsights1 {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.megamenuabouts {
  position: absolute;
  top: 95%;
  width: 100%;
  height: 400px;
  opacity: 0;
  visibility: hidden;
  left: 0px;
  /* box-shadow: 0px 10px 2px 3px rgba(0, 0, 0, 0.076); */
  transition: 0.5s;
}

nav > ul > li:hover .megamenuabouts {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.mobilenav {
  display: none;
}
.mobilesidepanel {
  display: none;
}

/* Mobile View */

@media only screen and (max-width: 992px) {
  .desktopnav {
    display: none;
  }
  .mobilenav {
    display: flex;
    padding:0 1em 0 1em;
    align-items: center;
    
  }
  .right-panel-container {
    padding: 0px 5px 5px 77px;
  }
  .desktopsidepanel {
    display: none;
  }
  .mobilesidepanel {
    display: unset;
  }
  .desktoppanel {
    display: none;
  }
  .top-nav {
    color: #221d35;
    font-size: 24px;
  }
  .ull {
    /* padding: 10px; */
    flex-direction: column;
  }
  .header-nav {
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    padding-bottom: 5px;
    padding-top: 4px;
  }
  .header-link {
    font-size: 24px;
    font-family: var(--CanelaLight);
  }
  .right-arrow {
    float: right;
    padding-top: 10px;
    display: block;
  }
}
